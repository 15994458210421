<template>
  <section class="m-user-coupon m-scroll">
    <!-- 头部导航 -->
    <m-header title="优惠券">
      <span class="header-right" slot="right" @click="show = true">兑换</span>
    </m-header>

    <!-- 兑换优惠券 -->
    <van-tabs v-model="tab" @change="handleTab" title-active-color="#ED2A24" line-height="0" class="tab">
      <van-tab title="未使用"></van-tab>
      <van-tab title="已使用"></van-tab>
      <van-tab title="已过期"></van-tab>
    </van-tabs>

    <!-- 优惠券列表 -->
    <van-list  v-model="loading" :finished="finished" :finished-text="list.length?'':'没有更多优惠券了，赶快去兑换吧'" @load="onLoad" :immediate-check="false" class="scroll-list">
      <div class="item" v-for="(it,index) in list" :key="index">
        <div class="it-image" >
          <img v-if="it.coupon.pic" :src="it.coupon.pic.url" alt="">
        </div>
        <div class="it-tips" :class="{'it-tips-open': it.open }">
          <p >{{it.coupon.rule}}</p>
          <img @click="it.open = !it.open" ref="down" src="@/assets/public/icon-xiala.png" alt="">
        </div>
      </div>
    </van-list>


    <!-- 兑换弹窗 -->
        <van-popup v-model="show" round position="bottom" :style="{ height: '35%' }" close-on-popstate >
            <p class="exchange-title">兑换优惠券</p>
            <div class="exchange-box">
              <textarea  ref="code"   placeholder="请输入获取的兑换码信息"  v-model="code"></textarea>
            </div>
            <div class="exchange-btn">
               <van-button @click="exchange()" type="primary" block round color="linear-gradient(315deg,rgba(240,60,24,1) 0%,rgba(240,100,72,1) 100%)">兑换</van-button>
            </div>
        </van-popup>


    
  </section>
</template>

<script>
import MHeader  from '@/components/zh/m-header.vue'
import { coupon, coupon_get } from '@/api/zh/mine.js'
export default {
  name:'Coupon',
  components:{ MHeader },
  data(){
    return {
      tab: 0,   // 筛选面板显示
      list: [],
      page: 1,
      loading: false,
      finished: false,

      show: false,
      code: '',
    }
  },


  methods:{

    // tab 发生改变
    handleTab() {
      this.finished = false
      this.list = []
      this.page = 1
      this.getData()
    },

    getData(type) {
      let params = { is_ok: this.tab + 1, page: this.page }
      coupon(params).then(res => {
        if(res) {
          res.data.data.forEach(e => {
            this.$set(e, 'open', false)
          })
          if(type && type == 'first'){
            this.list = res.data.data
          }else {
            this.list =  [...this.list, ...res.data.data]
          }
          
          if(res.data.current_page == res.data.last_page) {
              this.finished = true
          }
        }else{
          this.finished = true
        }
      }).finally( () => {
        this.loading = false
      })
    },

    onLoad() {
      if(!this.finished) {
        this.page ++ 
        this.getData()
      }
      
    },

    // 兑换优惠券
    exchange() {
      let params = {}
      if(this.code == ''){ 
          this.$notify({ type: 'warning', message: '请先输入优惠券兑换信息' })
          return false
      }else {
        params.code = this.code.split('%%')
        if(params.code.length > 1) {
          params.code = params.code[1]
        } else {
          params.code = params.code[0]
        }
      }

      coupon_get(params).then(res => {
        if(res) {
          this.page = 1;
          this.getData('first')
          this.$notify({ type: 'success', message: res.msg })
        }
      }).finally( () => {
         this.code = ''
         this.show = false
      })
      
    },


  },

  created() {
    this.getData()
  },


}
</script>

<style lang="less" scoped>
@import './index.less';
</style>